import {
    AuthenticationDetails,
    CognitoUserAttribute,
} from 'amazon-cognito-identity-js'
import axios from 'axios'
import { API_URL } from '../../constants'
import { getCognitoUser, getCognitoUserPool } from 'utils'

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

class AuthApi {
    login(email, password) {
        return new Promise(async (resolve, reject) => {
            let pool = getCognitoUserPool()
            let poolUser = getCognitoUser(email, pool)

            let authDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            })

            poolUser.authenticateUser(authDetails, {
                onSuccess: function (result) {
                    const accessToken = result.getIdToken().getJwtToken()

                    setSession(accessToken)

                    axios
                        .get(`${API_URL}/user?userId=${email}`)
                        .then((response) => {
                            const {
                                data: { resultData },
                            } = response
                            resolve(resultData)
                        })
                        .catch((err) => {
                            reject(err)
                        })
                },
                onFailure: function (err) {
                    reject(err)
                },
            })
        })
    }

    register(email, password) {
        return new Promise(async (resolve, reject) => {
            let pool = getCognitoUserPool()

            var attributeList = []

            var dataEmail = {
                Name: 'email',
                Value: email,
            }

            var attributeEmail = new CognitoUserAttribute(dataEmail)

            attributeList.push(attributeEmail)

            pool.signUp(
                email,
                password,
                attributeList,
                null,
                function (err, result) {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(result)
                    }
                }
            )
        })
    }

    forgotPassword(email) {
        return new Promise(async (resolve, reject) => {
            let pool = getCognitoUserPool()
            let poolUser = getCognitoUser(email, pool)

            poolUser.forgotPassword({
                onSuccess: function (data) {
                    resolve(data);
                },
                onFailure: function (err) {
                    reject(err);
                },
                inputVerificationCode: function (data) {
                    resolve(data)
                },
            })
        })
    }

    resetPassword(email,code,password) {
        return new Promise(async (resolve,reject) => {
            let pool = getCognitoUserPool()
            let poolUser = getCognitoUser(email, pool)

            poolUser.confirmPassword(code,password, {
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                }
            }) 
        })
    }
}

export const authApi = new AuthApi()
