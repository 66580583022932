import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const UserManagement = Loadable(lazy(() => import("./UserManagement")));
const FeedbackStastics = Loadable(lazy(() => import("./FeedbackStastics")));
const IrrigationDashboard = Loadable(lazy(() => import("./IrrigationDashboard")));
const UserDetails = Loadable(lazy(() => import("./UserManagement/UserDetails")));
const EditUser = Loadable(lazy(() => import("./UserManagement/EditUser")));
const MasterAdminDashboard = Loadable(lazy(() => import("./MasterAdminDashboard")));
const AlertHistory = Loadable(lazy(() => import("./MasterAdminDashboard/AlertHistory")));
const DeviceAlerts = Loadable(lazy(() => import("./MasterAdminDashboard/DeviceAlerts")));
const Dashboard = Loadable(lazy(() => import("./EndUserDashboard")));

const pageRoutes = [
    {
        path: '/userManagement',
        element: <UserManagement />,
        auth: ['Master Admin']
    },
    {
        path: '/userManagement/view',  //TODO: pass user params to path
        element: <UserDetails />,
        auth: ['Master Admin']
    },
    {
        path: '/userManagement/edit',  //TODO: pass user params to path
        element: <EditUser />,
        auth: ['Master Admin']
    },
    {
        path: '/feedbackstastics',
        element: <FeedbackStastics />,
        auth: ['Master Admin', 'Sub Admin']
    },

    {
        path: '/irrigationDashboard',
        element: <IrrigationDashboard />,
        auth: ['Master Admin', 'Sub Admin', 'Management']
    },
    {
        path: '/admin/dashboard',
        element: <MasterAdminDashboard />,
        auth: ['Master Admin']
    },
    {
        path: '/admin/dashboard/alertHistory',
        element: <AlertHistory />,
        auth: ['Master Admin']
    },
    {
        path: '/admin/dashboard/deviceAlerts',
        element: <DeviceAlerts />,
        auth: ['Master Admin']
    },
    {
        path: '/dashboard',
        element: <Dashboard />,
        auth: ['Sub Admin', 'Management']
    },


]

export default pageRoutes;