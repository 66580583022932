import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
// import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import pageRoutes from 'app/pages/PageRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'


export const AllPages = () => {
    const user = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : null;
    const role = user ? user.role : null;

    const updatedPageRoutes = pageRoutes.filter((val) => {
        if (val.auth.includes(role)) {
            return val;
        }
         else{
             return null;
         }      
    });

    const all_routes = [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [...updatedPageRoutes],
        },
        ...sessionRoutes,
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
