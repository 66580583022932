import React from 'react'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { Routes, Route, Navigate, useRoutes } from 'react-router-dom'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { MatxTheme } from 'app/components'
import { AllPages } from './routes/routes'

const App = () => {
    const all_pages = useRoutes(AllPages())
    const user = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : null;
    const role = user ? user.role : null;

    return (
            <SettingsProvider>
                <MatxTheme>
                    <AuthProvider>
                        {all_pages}
                        <Routes>
                            <Route path='/' element={role && role === 'Master Admin' ? <Navigate to="/admin/dashboard" /> : role ? <Navigate to="/dashboard" /> : <Navigate to="/session/signin" />} />
                        </Routes>
                    </AuthProvider>
                </MatxTheme>
            </SettingsProvider>
    )
}

export default App
